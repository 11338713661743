<template>
  <div>
    <v-toolbar
      color="white"
      elevation="0"
      outlined
      dense
      style="margin-bottom: 1px"
    >
      <v-tabs v-model="tab">
        <v-tab :to="{ name: 'leads_list' }" exact>
          {{ __("Leads", "leads") }}
        </v-tab>
        <v-tab :to="{ name: 'leads_notifications' }">
          <v-badge
            :content="unread_lead_notifications"
            :value="unread_lead_notifications"
            color="green"
          >
            <span>{{ __("Notificações", "notifications") }}</span>
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import i18n from "@/mixins/i18n";

export default {
  mixins: [i18n],
  data: () => ({
    tab: null,
    tabs: [
      {
        name: "Leads",
        route: "leads_list",
      },
      {
        name: "Notificações",
        route: "leads_notifications",
      },
    ],
  }),
  computed: {
    ...mapState("lead_notifications", ["unread_lead_notifications"]),
  },
};
</script>

<style>
.table-cursor tbody tr:hover {
  cursor: pointer;
}
</style>
